import { fetch, post } from '@/utils/axios'

export default {
    getRefreshToken: (params) => {
        return fetch('/Token/Refresh/App', params)
    },
    login: (token) => {
        return fetch(`/Token/${token}`)
    },
    userInfo: () => {
        return fetch('/app/Users')
    },
    realName: (data) => {
        return post('/app/Users/realName', data)
    },
    editNickName: (data) => {
        return post('/app/Users/nickName', data)
    },
    createBank: (data) => {
        return post('/app/Users/add/Bank', data)
    },
    updateBank: (data) => {
        return post('/app/Users/update/bank', data)
    },
    cashFirst: (data) => {
        return post('/app/Users/password/cash/first', data)
    },
    editCash: (data) => {
        return post('/app/Users/password/cash', data)
    },
    editPassword: (data) => {
        return post('/app/Users/password', data)
    },
    register: (data) => {
        return post('/Event/add/customer', data)
    },
    news: (params) => {
        return fetch('/Event/news', params)
    },
    getbasic: () => {
        return fetch('/Event/basic')
    },
    getOpenBank: () => {
        return fetch('/app/Users/OpenBank/idName')
    },
    addSwift: (data) => {
        return post('/app/Users/add/Swift', data)
    },
    updateSwift: (data) => {
        return post('/app/Users/update/Swift', data)
    },
    SWIFTCodeIdName: () => {
        return fetch('/app/Users/SwiftCode/idName')
    },
    agreement: (params) => {
        return fetch('/Event/agreement', params)
    },
    childs: (params) => {
        return fetch('/app/Users/childs', params)
    },
    rotationPics: (params) => {
        return fetch('/Event/rotationPics', params)
    },
    // 上传文件
    upCaptchaFile: (data) => {
        return post('/Captcha/file', data)
    },
      //用户站内消息列表
  getUserMessageList: (params) => {
    return fetch('/app/Users/getUserMessageList', params)
  },
  //更新用户站内消息已读
  upUserMessageRead: () => {
    return fetch('/app/Users/upUserMessageRead')
  },
}